/*-------------------------
    COUPLE INTRO CSS START
--------------------------*/
section.couple-section{
    position: relative;
}
section.couple-section .z_index{
    z-index:1;
}
.shape_1,
.shape_2{
    width: 400px;
    z-index:-1;
    position: absolute;
    top:0; 
}
.shape_1{      
    left:0;     
}
.shape_2{        
    right:0;     
}
.couple-section .middle-couple-pic {    
    position: relative;    
    max-width:80%;
    margin:0 auto;
}
.couple-section .middle-couple-pic .frame-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 125%;
}
.couple-section .middle-couple-pic img {
    border-radius: 235px;
}
.couple-section h3 {    
    font-size: 22px;
    margin: 0 0 15px 0;
}
.couple-section .couple-img {
    margin-bottom: 20px;
    width: 125px;
    height: 125px;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 6.1px 3.9px rgb(28 31 86 / 6%);
    overflow: hidden;
    padding: 10px;
}
.couple-section .couple-img img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.couple-section .groom .couple-img {
    margin: 0 auto 20px 0 ;
}
.couple-section ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
    padding-top: 15px;
    display: flex;
    align-items:center;
}
.couple-section .bride  ul{
    justify-content: flex-end;
}
.couple-section ul > li + li {
    margin-left: 25px;
}
.couple-section ul li a {
    display: block;
    text-align: center;
    color: #738ea5;
    text-decoration:none;
    font-size: 18px;
}
.couple-section ul li a:hover {
    color: #86a0b6;
}

@media(max-width:1199px){
    section.couple-section{
        padding:100px 0;
    }
    .shape_1, .shape_2 {
        width: 25%;        
    }
}
@media(max-width:991px){
    .couple-section .couple-img,
    .couple-section .groom .couple-img{
        margin:0 auto;
    }
    .couple-section ul,
    .couple-section .bride ul{
        justify-content: center;
        padding-top: 0;
    }
    .couple-section .middle-couple-pic img{
        width: 0%;
        margin:0;
    }
    .couple-section h3{
        margin-top: 15px;
    }
}
@media(max-width:767px){
    .shape_1, .shape_2 {
        width: 25%;        
    }
    .couple-section .middle-couple-pic{
        width: 100%;
        padding:30px 0;
    }
    .couple-section .middle-couple-pic img{
        width: 100%;
    }
}
@media(max-width:480px){
    .shape_1, .shape_2 {
        width: 40%;        
    }
}
/*-------------------------
    COUPLE INTRO CSS END
--------------------------*/