/*--------------------
    Slider CSS START
---------------------*/

.carousel-item {
  transition: opacity 1.3s ease-in-out !important;
}
.carousel-item::before{
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  z-index: 1;
  background: #00315f;
  opacity:0.3;
}

.carousel-control-prev,
.carousel-control-next{
  background: rgba(255, 255, 255, 0.2);
  width: 50px;
  height: 50px;
  line-height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  opacity: 0.4 !important;
  text-align: center;
  transition: all 0.3s;
  z-index:9999999 !important;
  cursor: pointer;
  top:50%;
  transform:translateY(-50%);
  z-index:2 !important;
}
.carousel-control-prev{
  left:3%;
}
.carousel-control-next{
  right:3%;
}
.carousel-control-prev:hover,
.carousel-control-next:hover{
  opacity:0.8 !important;
}
.carousel-control-next-icon, 
.carousel-control-prev-icon{
  width: 20px;
  height: 20px;
}
@media(max-width:1199px) {
  .carousel-control-prev,
  .carousel-control-next{
    width: 30px;
    height:30px;    
  }
}
@media(max-width:767px) { 
  .carousel-control-prev{
    left:2%;
  }
  .carousel-control-next{
    right:2%;
  }
}

/*--------------------
    Slider CSS END
---------------------*/