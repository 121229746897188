section.gallery_sec{    
    margin-bottom: 125px;    
}
.galleryBox{
    position: relative;
    z-index:999;
    min-height:300px;
    max-height:300px;
    box-sizing:border-box;
}
.galleryBox.box_4_4_Box{
    min-height:620px;
}
.grid_row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.grid_row  .galleryBox  img{
    width:100%;
    min-width:100%;
    height:100%;
    min-height:100%;
    object-fit:cover;
}
.grid_row .galleryBox.box_2_start{
    grid-column-start: 1;
    grid-column-end: 3;
}
.grid_row .galleryBox.box_2_end{
    grid-column-start: 3;
    grid-column-end: 5;
}
.grid_row .galleryBox.box_4_4_Box{
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;    
}
.grid_row .galleryBox.box_50_start{
    grid-column-start: 1;
    grid-column-end: 3;
}
.grid_row .galleryBox.box_50_end{
    grid-column-start: 3;
    grid-column-end: 5;
}
.galleryBox:before{
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    right: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    content: "";
    background: rgba(255, 255, 255, 0.9);
    opacity: 0;
    transition: all 0.3s;
    transform: scale(0);
    cursor: pointer;
}
.galleryBox .iconBox{
    transition: all 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    text-align: center;    
}
.galleryBox .iconBox i{
    font-size: 30px;
    color: #002642;
}
.galleryBox:hover:before{
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.galleryBox:hover .iconBox{
    opacity: 1;
}

@media(max-width:1199px){
    .galleryBox{
        min-height:225px;
        max-height:225px;
    }
    .galleryBox.box_4_4_Box{
        min-height:470px;
    }
    section.gallery_sec{
        margin-bottom: 0;
        padding-bottom: 80px !important;
    }
}
@media(max-width:991px){
    .galleryBox{
        min-height:150px;
        max-height:150px;
    }
    .galleryBox.box_4_4_Box{
        min-height:320px;
    }
}
@media(max-width:767px){
    .grid_row{
        gap:12px;
    }
    section.gallery_sec{
        padding:50px 0 !important;
    }
    .galleryBox{
        min-height:100px;
        max-height:100px;
    }
    .galleryBox.box_4_4_Box{
        min-height:220px;
    }
}
@media(max-width:575px){
    .grid_row {        
        grid-template-columns: repeat(3, 1fr);
    }
    .galleryBox,
    .grid_row .galleryBox.box_4_4_Box {
        min-height:125px;
        max-height:125px;
    }
    .grid_row .galleryBox {
        grid-column-start: unset !important;
        grid-column-end: unset !important;
        grid-row-start: unset !important;
        grid-row-end: unset !important;
    }
}
@media(max-width:420px){
    .grid_row {        
        grid-template-columns: repeat(2, 1fr);
    }
    .grid_row .galleryBox:last-child{
        display: none;
    }
}